import React from "react"
import { Container, Row } from "reactstrap"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

let StyledFeature = styled.div`
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  transition-duration: 0.25s;
  margin: 0 10px;
  &:hover {
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  }
`

let Feature = ({ img }) => (
  <StyledFeature>
    <Img fixed={img} />
  </StyledFeature>
)

let OrdiPics = () => {
  const data = useStaticQuery(graphql`
    fragment defaultOrdiImage on File {
      childImageSharp {
        fixed(height: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    query ordiQuery {
      slide1: file(relativePath: { eq: "Therapie.jpg" }) {
        ...defaultOrdiImage
      }
      slide2: file(relativePath: { eq: "Warteraum.jpg" }) {
        ...defaultOrdiImage
      }
      slide3: file(relativePath: { eq: "Ordi_1.jpg" }) {
        ...defaultOrdiImage
      }
    }
  `)
  return (
    <Container className="py-5">
      <Row>
        <Feature img={data.slide1.childImageSharp.fixed} />
        <Feature img={data.slide2.childImageSharp.fixed} />
        <Feature img={data.slide3.childImageSharp.fixed} />
      </Row>
    </Container>
  )
}

export default OrdiPics
