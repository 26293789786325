import React from "react"
import PageTemplate from "../components/pageTemplate"
import {
  Card,
  CardTitle,
  CardGroup,
  CardSubtitle,
  CardBody,
} from "reactstrap"
import OrdiPics from "../components/ordiPics"
import Hr from "../components/hr"
import { FaBookMedical } from "react-icons/fa"

let Pointmark = ({ title, content }) => (
  <div className="d-flex mb-4">
    <FaBookMedical size={30} className="text-primary" />
    <div className="ml-3">
      <h4>{title}</h4>
      <p className="m-0 text-muted">{content}</p>
    </div>
  </div>
)

let Ordination = () => (
  <PageTemplate title="Ordination">
    <CardGroup>
      <Card>
        <CardBody>
          <CardTitle tag="h5">Kassenleistungen</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">
            Allgemeines
          </CardSubtitle>
            <ul>
              <li>Ordination</li>
              <li>Hausbesuche</li>
              <li>Gesundenuntersuchung</li>
              <li>Mutter- Kindpass Untersuchungen </li>
              <li>EKG</li>
              <li>24h- Blutdruckmessung</li>
              <li>Labor</li>
              <li>Gerinnungskontrollen</li>
              <li>
                Impfungen für Kinder (kostenlos lt.{" "}
                <a
                  href="https://www.sozialministerium.at/Themen/Gesundheit/Impfen/Impfplan-%C3%96sterreich.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Impfplan Österreich
                </a>
                )
              </li>
            </ul>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <CardTitle tag="h5">Kassenleistungen</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">
            Therapien
          </CardSubtitle>
            <ul>
              <li>Ultraschall</li>
              <li>Reizstrom</li>
              <li>Mikrowelle</li>
              <li>Inhalationen</li>
              <li>Injektionen</li>
              <li>Infusionen</li>
            </ul>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <CardTitle tag="h5">Privatleistungen</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">
            &nbsp;
          </CardSubtitle>
            <ul>
              <li>Führerscheingutachten</li>
              <li>Impfungen</li>
              <li>Atteste</li>
              <li>Bestätigungen</li>
            </ul>
            Labordiagnostik:
            <ul>
              <li>Troponin</li>
              <li>D-Dimere (Thrombose)</li>
              <li>Akutdiagnose Herzinfarkt</li>
              <li>PSA</li>
              <li>Covid-19 Antikörper</li>
              <li>Covid-19 Antogen</li>
            </ul>
            Komplementärmedizin:
            <ul>
              <li>Körper- &amp; Ohrakupunktur</li>
            </ul>
        </CardBody>
      </Card>
    </CardGroup>
    <Hr />
    <h2>Chronik</h2>
    <figure className="text-left">
      <blockquote className="blockquote">
        <p>
          Nach einer Zeit des Zerfalls kommt die Wendezeit. Es gibt Bewegung.
          Darum ist die Umgestaltung des Alten auch ganz leicht. Altes wird
          abgeschafft, Neues wird eingeführt, beides entspricht der Zeit und
          bringt daher keinen Schaden.
        </p>
      </blockquote>
      <figcaption className="blockquote-footer">
        <cite title="I Ging">I Ging</cite>
      </figcaption>
    </figure>
    <Pointmark
      title="11.2009 - Gründung"
      content="Nach knapp 6 Wochen Vorbereitungszeit, engagiertem Vorgehen des Herrn Bürgermeisters Johann Puchmüller, der Gemeinde St.Marein bei Graz und aller beteiligten Unternehmen war es nun doch möglich, die Ordination per 02.11.2009 zu eröffnen. Hiermit bedanke ich mich bei allen Beteiligten aufs Herzlichste!"
    />
    <Pointmark
      title="09.2015 - Umzug"
      content="Umzug mit der Ordination nach Markt 180: diese Räumlichkeiten im Erdgeschoss des Betreuten Wohnens ermöglichen für die Patienten einen komfortableren Zugang zur Ordination durch Barrierefreiheit, Parkmöglichkeiten und Nähe zur Apotheke und dem Gewerbezentrum."
    />
    <figure className="text-left">
      <blockquote className="blockquote">
        <p>
          Und jedem Anfang wohnt ein Zauber inne, der uns beschützt und der uns
          hilft, zu leben.
        </p>
      </blockquote>
      <figcaption className="blockquote-footer">
        <cite title="Hermann Hesse">Hermann Hesse</cite>
      </figcaption>
    </figure>
    <Hr />
    <OrdiPics />
  </PageTemplate>
)

export default Ordination
